import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../actions/userActions';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { Password } from 'primereact/password';
import { PreLoginWrapper } from './PreLoginWrapper';

export const Login = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const localtoken = localStorage.getItem('authToken');
    const navigate = useNavigate();

    useEffect(() => {
        if (localtoken) {
            navigate('/fields');
        }
    }, [localtoken, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        await login(email, password, navigate).then((response) => {
            setError(t(`login.${response}`));
        });
    };

    return (
        <PreLoginWrapper error={error}>
            <p className="p-component mb-5 font-semibold w-full">{t('login.info_message')}</p>
            
            <form className='w-full' onSubmit={handleSubmit}>

                <IconField className="w-full mb-3" iconPosition="left">
                    <InputIcon 
                        className="pi pi-envelope ml-2" 
                        style={{ color: 'black' }}
                        aria-label="Email icon"
                        tabIndex="-1"
                    />
                    <InputText 
                        placeholder={t('login.placeholder_email')}
                        type="email"
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        className="w-full pl-6"
                        required
                    />
                </IconField>
                
                <IconField className="w-full mb-5" iconPosition="left">
                    <InputIcon 
                        className="pi pi-lock ml-2" 
                        style={{ color: 'black', zIndex: 1 }}
                        aria-label="Password icon"
                        tabIndex="-1"
                    />
                    <Password 
                        placeholder={t('login.placeholder_password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} 
                        feedback={false} 
                        toggleMask 
                        inputClassName="w-full pl-6"
                        className="w-full"
                        pt={{ iconField: { root: { className: 'w-full' } } }} 
                        required
                    />
                </IconField>

                <Button 
                    label={loading ? t('login.loading_message') : t('login.login_message')} 
                    disabled={loading} 
                    className="bg-primary w-full" 
                />

            </form>

            <div className="flex flex-column align-items-center justify-content-between pt-5">
                <a className="no-underline pb-3 text-white cursor-pointer" onClick={() => navigate('/recoverPassword')}>{t('login.recover_message')}</a>
                <a className="no-underline">{t('login.register_message')}  <a className="no-underline text-white cursor-pointer" onClick={() => navigate('/register')}>{t('login.here_button')}</a></a>
            </div>
        </PreLoginWrapper>
    );
};
