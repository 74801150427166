import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getField, getCrops, deleteField, getGuests, deleteGuest, fetchNotifications } from '../actions/fieldsActions';
import { getUser } from '../actions/userActions';
import { postNematode, postSolarization, putNematode, putSolarization } from '../actions/modelActions'; 
import { getSignalStatus, getModel, getModelId, getOwner, getColor, getColorFooter, getColorCard, getColorCardFooter } from '../utils/fieldUtils';
import { ModalGeneric } from './ModalGeneric';
import { ModalInterpolate } from './ModalInterpolate';
import { avalibleLicence, formatDateLicence, expirationDays } from '../utils/functions';
import Map from './Map';
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Timeline} from 'primereact/timeline';
import { getTemperatureData, getPredictionModel, getPredictionModelJ2, getSolarizationData, getExcelPredictionModel, getExcelSolarizationData, getExcelTemperatureData } from '../actions/temperatureActions';
import 'chartjs-plugin-gradient'; // Importa el plugin
import { PostLoginWrapper } from './PostLoginWrapper';
import { useTranslation } from 'react-i18next';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { ProgressBar } from 'primereact/progressbar';
import { InputSwitch } from 'primereact/inputswitch';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import well_signal from '../assets/well_signal_black.svg';
import half_signal from '../assets/half_signal_black.svg';
import low_signal from '../assets/low_signal_black.svg';
import not_signal from '../assets/not_signal_black.svg';
import low_battery from '../assets/low_battery_black.svg';
import half_battery from '../assets/half_battery_black.svg';
import well_battery from '../assets/well_battery_black.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faDownload, faMapMarkerAlt, faTrash, faCheck, faTimes, faExpand } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

export const Field = () => {
    const { fieldId } = useParams();
    const navigate = useNavigate(); 
    const [errorMessage, setErrorMessage] = useState('');
    const [fieldData, setFieldData] = useState(null);
    const [crop, setCrop] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [haveModel, setHaveModel] = useState('');
    const [isInterpolateModalOpen, setIsInterpolateModalOpen] = useState(false);
    const [initDate, setInitDate] = useState(null);
    const [lastDate, setLastDate] = useState(new Date());
    const [chartData1, setChartData1] = useState([]);
    const [chartData2, setChartData2] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInit, setIsLoadingInit] = useState(true);
    const { t } = useTranslation();
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [isLoadingTrigger, setIsLoadingTrigger] = useState(false);
    const [isMapDialogVisible, setIsMapDialogVisible] = useState(false);
    const [showStartModelDialog, setShowStartModelDialog] = useState(false);
    const [showStopNematodeDialog, setShowStopNematodeDialog] = useState(false);
    const [showStopSolarizationDialog, setShowStopSolarizationDialog] = useState(false);
    const [showDeleteFieldDialog, setShowDeleteFieldDialog] = useState(false);
    const [showDownloadDataDialog, setShowDownloadDataDialog] = useState(false);
    const [currentModel, setCurrentModel] = useState(null);
    const [isChartModalVisible, setIsChartModalVisible] = useState(false);
    const [address, setAddress] = useState('');
    const [currentChart, setCurrentChart] = useState(1);
    const [notifications, setNotifications] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        getField(fieldId).then((field) => {
            if (field == null) {
                navigate("/fields");
            }
        })
        const fetchUserData = async () => {
            try {
                const userData = await getUser();
                setUserEmail(userData.email);
                setUserId(userData.id);
                if (fieldId) {
                    const fieldData = await getField(fieldId);
                    setFieldData(fieldData);
                    const cropsData = await getCrops();
                    const cropData = cropsData.results.find(crop => crop.id === fieldData.crop);
                    setCrop(cropData.id);
                    setIsLoadingInit(false);
                } else {
                    console.error("El fieldId es undefined");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [fieldId, refreshTrigger]);


    useEffect(() => {
        let newDateInit = new Date();
        
        if (fieldData) {
            let sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

            if(fieldData.active_prediction_model){
                newDateInit = new Date(fieldData.active_prediction_model.started.replace('Z', ''));
                setHaveModel('N');
            }
            else if(fieldData.active_solarization){
                newDateInit = new Date(fieldData.active_solarization.started.replace('Z', ''));
                setHaveModel('S');
            }
            else{
                newDateInit = sevenDaysAgo;
                setHaveModel('No model');
            }

            if (newDateInit > sevenDaysAgo) {
                newDateInit = sevenDaysAgo;
            }
        
            setInitDate(newDateInit);
            setIsLoading(true);
            const timer = setTimeout(() => {
                fetchChartData(fieldData.id, getModelId(fieldData), newDateInit, lastDate);
            }, 1000);
            
            return () => clearTimeout(timer);
        }
    }, [fieldData]);

    const f = fieldData;

    let licenseStatus;
    if (f) {
        licenseStatus = avalibleLicence(f.expiration_license_date);
        licenseStatus = true;
    }

    let lat, lon;
    if (f && f.area && Array.isArray(f.area.coordinates) && f.area.coordinates.length === 2) {
        lon = f.area.coordinates[0];
        lat = f.area.coordinates[1];
    } else {
        lat = 0;
        lon = 0;
    }
    const ownerEmail = f ? getOwner(f) : '';

    useEffect(() => {
        if (lat && lon) {
            const fetchAddress = async () => {
                try {
                    const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
                        params: {
                            lat,
                            lon,
                            format: 'json',
                            'accept-language': navigator.language || 'en'
                        }
                    });
                    setAddress(response.data.display_name);
                } catch (error) {
                    console.error('Error fetching address:', error);
                }
            };

            fetchAddress();
        }
    }, [lat, lon]);

    const handleStartModel = () => {
        setShowStartModelDialog(true);
    };

    const handleStartNematode = async () => {
        if (f) {
            await postNematode(f.id);
            setIsInterpolateModalOpen(true);
            setIsLoadingTrigger(true);
        }
    };

    const handleStartSolarization = async () => {
        if (f) {
            await postSolarization(f.id);
            setIsLoadingTrigger(true);
            setRefreshTrigger(prev => !prev);
        }
    };

    const handleStopNematode = () => {
        setShowStopNematodeDialog(true);
    };

    const handleStopSolarization = () => {
        setShowStopSolarizationDialog(true);
    };

    const handleDeleteField = () => {
        setShowDeleteFieldDialog(true);
    };

    const handleMapLoad = (position) => {
        // Puedes realizar otras acciones cuando el mapa se carga
    };

    const handleClickBuyLicense = (sensorNumber) => {
        navigate('/create/field/license', { state: { sensorNumber } });
    };

    const handleClickLinkSensor = () => {
        navigate('/link/sensor', { state: { fieldId } });
    };

    const closeInterpolateModal = () => {
        setIsInterpolateModalOpen(false);
        setRefreshTrigger(prev => !prev);
    };

    const formatDateSensor = (date) => {
        const dateSensor = new Date(date.replace('Z', ''));
        let now = new Date(new Date().toLocaleString('en-US', { timeZone: 'GMT' }));

        const diffMs = now - dateSensor;
        const diffMinutes = Math.floor(diffMs / (1000 * 60));
        const diffHours = Math.floor(diffMinutes / 60);
        const diffDays = Math.floor(diffHours / 24);

        if (diffDays > 0) {
            return `${diffDays} días`;
        } else if (diffHours > 0) {
            return `${diffHours} horas`;
        } else {
            return `${diffMinutes} minutos`;
        }
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    };


    const formatDateChart = (date, interval) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        if (interval === 'H') {
            return `${day}-${month}-${year} ${hour}:00`;
        } else {
            return `${day}-${month}-${year}`;
        }

    };

    const fetchChartData = async (fieldId, model, initDate, lastDate) => {
        setIsLoading(true);

        const formattedInitDate = formatDate(initDate);
        const nextDay = new Date(lastDate);
        nextDay.setDate(nextDay.getDate() + 1);
        const formattedLastDate = formatDate(nextDay);
        let modelName = t('field.solarization');
        let modelNameJ2 = "";
        let modelData = [];
        let modelDataJ2 = [];
        let interval = 'H';
        
        if (model && model[0] === "N") {
            interval = "D";
            modelName = t('field.ovicide');
            modelNameJ2 = t('field.larvicide');
            
            // Obtener datos del modelo
            modelData = await getPredictionModel(model[1], formattedInitDate, formattedLastDate);
            modelDataJ2 = await getPredictionModelJ2(model[1], formattedInitDate, formattedLastDate);

            if (events.length == 0){
            
                // Agregar el evento de inicio del modelo
                const modelStartEvent = [{
                    status: 'start_model',
                    date: formattedInitDate
                }];

                // Filtrar para obtener solo la última generación
                const lastGeneration = Math.max(...modelData.map(entry => entry.generation));
                let modelDataAux = modelData.filter(entry => entry.generation === lastGeneration);
                let modelDataAuxJ2 = modelDataJ2.filter(entry => entry.generation === lastGeneration);

                // Procesar notificaciones para huevos
                const eggWarnings = modelDataAux
                    .filter(entry => entry.ratio_eggs * 100 >= 80 && entry.ratio_eggs * 100 < 100)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'nematode_head_warning_eggs',
                        date: entry.date
                    }));
                    
                const eggAlerts = modelDataAux
                    .filter(entry => entry.ratio_eggs * 100 >= 100)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'nematode_head_alert_eggs',
                        date: entry.date
                    }));
                    
                // Procesar notificaciones para larvas
                let larvaeWarnings = [];
                let larvaeAlerts = [];
                
                if (sensorNumber.startsWith('P')) {
                    larvaeWarnings = modelDataAuxJ2
                        .filter(entry => entry.ratio_larvicide >= 80 && entry.ratio_larvicide < 100)
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .slice(0, 1)
                        .map(entry => ({
                            status: 'nematode_head_warning_larvae',
                            date: entry.date,
                            color: 'yellow'
                        }));

                    larvaeAlerts = modelDataAuxJ2
                        .filter(entry => entry.ratio_larvicide >= 100)
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .slice(0, 1)
                        .map(entry => ({
                            status: 'nematode_head_alert_larvae',
                            date: entry.date
                        }));
                }

                // Combinar todas las notificaciones y ordenar por fecha descendiente
                const timeline_values = [...eggWarnings, ...eggAlerts, ...larvaeWarnings, ...larvaeAlerts, ...modelStartEvent]
                    .sort((a, b) => new Date(b.date) - new Date(a.date));

                // Actualizar las notificaciones
                setEvents([...timeline_values]);

            }
        }
        else if (model && model[0] === "S") {
            modelData = await getSolarizationData(fieldId, formattedInitDate, formattedLastDate);

            if (events.length == 0){
                
                // Agregar el evento de inicio del modelo
                const modelStartEvent = [{
                    status: 'start_model',
                    date: formattedInitDate
                }];

                const lowEvent = modelData
                    .filter(entry => entry.degrees >= 50 && entry.degrees < 500)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'low',
                        date: entry.date
                    }));

                // Encontrar la primera ocurrencia de cada nivel
                const moderateEvent = modelData
                    .filter(entry => entry.degrees >= 500 && entry.degrees < 1500)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'moderate',
                        date: entry.date
                    }));


                const goodEvent = modelData
                    .filter(entry => entry.degrees >= 1500 && entry.degrees < 3000)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'good',
                        date: entry.date
                    }));

                const excellentEvent = modelData
                    .filter(entry => entry.degrees >= 3000 && entry.degrees < 6000)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'excellent',
                        date: entry.date
                    }));


                const superEvent = modelData
                    .filter(entry => entry.degrees >= 7000)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'super',
                        date: entry.date
                    }));


                // Combinar todos los eventos y ordenar por fecha descendiente
                const timeline_values = [...modelStartEvent, ...lowEvent, ...moderateEvent, ...goodEvent, ...excellentEvent, ...superEvent]
                    .sort((a, b) => new Date(b.date) - new Date(a.date));

                // Actualizar las notificaciones
                setEvents([...timeline_values]);
            }
        }

        const temperatureData = await getTemperatureData(fieldId, interval, formattedInitDate, formattedLastDate);
        const labels = Object.keys(temperatureData).map(dateString => formatDateChart(new Date(dateString), interval));
        const temperatureDataset = Object.values(temperatureData).map(entry => entry.mean);

        let modelDataset = [];
        let modelDatasetJ2 = [];
        let modelDatasetJ2Color = [];
        if (model && model[0] === "N"){  
            modelDataset = null;
            if (modelData && modelData.length > 0){
                modelDataset = labels.map(label => {
                    const modelEntry = modelData.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                    return modelEntry ? modelEntry.ratio_eggs * 100 : null;
                });
            }
            modelDatasetJ2 = labels.map(label => {
                const modelEntry = modelDataJ2.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.ratio_larvicide : null;
            });
            modelDatasetJ2Color = labels.map(label => {
                const modelEntry = modelDataJ2.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.color : null;
            });
        }
        else if (model && model[0] === "S"){
            modelDataset = labels.map(label => {
                const modelEntry = modelData.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.degrees : null;
            });
        }

        const getColor = (value) => {
            if (model && model[0] === "S"){
                return '#8AE640';
            }

            if (value == null) return 'transparent';
            if(value > 130) return '#42A5F5';
            if(value > 100) return '#E03642';
            if(value > 80) return '#F9794A';

            if(value == 'blue') return '#67AAFB';
            if(value == 'green') return '#7ECA22';
            if(value == 'orange') return '#F6894E';
            if(value == 'red') return '#ED5F55';

            return '#8AE640';
        };

        const datasets1 = [
            {
                label: t('field.temperature'),
                fill: false,
                borderColor: '#8A99AD',
                tension: .4,
                data: temperatureDataset,
                yAxisID: 'y',
                backgroundColor: 'transparent',
                pointBackgroundColor: '#8A99AD',
                pointBorderColor: '#8A99AD',
                pointRadius: window.innerWidth < 500 ? 0 : 2,
                pointHoverRadius: window.innerWidth < 500 ? 0 : 5,
                borderWidth: 3,
            }
        ];

        const datasets2 = [...datasets1];

        if (model && modelData.length > 0) {
            let ultimoValor = modelDataset[modelDataset.length - 1];
            let colorUltimo = getColor(ultimoValor);
            datasets1.push({
                label: modelName,
                borderColor: colorUltimo,
                fill: false,
                tension: .4,
                data: modelName === 'Ovicida' ? modelDataset.map(value => (value > 100 ? 100 : value)) : modelDataset.map(value => (value > 7000 ? 7000 : value)),
                yAxisID: 'y1',
                pointBackgroundColor: modelDataset.map(value => getColor(value)),
                pointBorderColor: modelDataset.map(value => getColor(value)),
                pointRadius: window.innerWidth < 500 ? 0 : 2,
                pointHoverRadius: window.innerWidth < 500 ? 0 : 5,
                borderWidth: 3,
                segment: {
                    borderColor: ctx => getColor(modelDataset[ctx.p1DataIndex]),
                    backgroundColor: ctx => `${getColor(modelDataset[ctx.p1DataIndex])}33`
                }
            });

            if (model[0] === "N" && sensorNumber.startsWith('P')){
                let ultimoValorJ2 = modelDatasetJ2Color[modelDatasetJ2Color.length - 1];
                let colorUltimoJ2 = getColor(ultimoValorJ2);
                datasets2.push({
                    label: modelNameJ2,
                    borderColor: colorUltimoJ2,
                    fill: false,
                    tension: .4,
                    data: modelDatasetJ2.map(value => (value > 100 ? 100 : value)),
                    yAxisID: 'y1',
                    pointBackgroundColor: modelDatasetJ2Color.map(value => getColor(value)),
                    pointBorderColor: modelDatasetJ2Color.map(value => getColor(value)),
                    pointRadius: window.innerWidth < 500 ? 0 : 2,
                    pointHoverRadius: window.innerWidth < 500 ? 0 : 5,
                    borderWidth: 3,
                    segment: {
                        borderColor: ctx => getColor(modelDatasetJ2Color[ctx.p1DataIndex]),
                        backgroundColor: ctx => `${getColor(modelDatasetJ2Color[ctx.p1DataIndex])}33`
                    }
                });
            }
        }

        setChartData1({
            labels,
            datasets: datasets1
        });


        setChartData2({
            labels,
            datasets: datasets2
        });

        setIsLoading(false);
        setIsLoadingTrigger(false);
    };

    const getLightTheme = () => {
        let multiAxisOptions = {
            animation: {
                duration: 0 // Desactiva la animación
            },
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true,
                        boxWidth: 10,
                        boxHeight: 10,
                        padding: 10,
                        color: '#000000',
                        pointStyle: 'circle',
                        generateLabels: (chart) => {
                            const datasets = chart.data.datasets;
                            return datasets.map((dataset, i) => ({
                                text: dataset.label,
                                fillStyle: dataset.borderColor,
                                strokeStyle: dataset.borderColor,
                                lineWidth: 2,
                                hidden: !chart.isDatasetVisible(i),
                                index: i
                            }));
                        }
                    },
                    onClick: (e, legendItem, legend) => {
                        const index = legendItem.index;
                        const ci = legend.chart;
                        const meta = ci.getDatasetMeta(index);
                        meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
                        ci.update();
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    },
                    title: {
                        display: true,
                        text: t('field.temperature_axis'),
                        color: '#495057'
                    }
                },
                ...(haveModel!= "No model" ? {
                    y1: {
                        beginAtZero: true,
                        type: 'linear',
                        display: true,
                        position: 'right',
                        min: 0,
                        max: haveModel === "N" ? 105 : 7700,
                        ticks: {
                            ...(haveModel === "N" ? {
                                stepSize: 5,
                                callback: (value) => {
                                    if (value <= 100) {
                                        return value;
                                    }
                                    return '';
                                }
                            } : {
                                stepSize: 700,
                                callback: (value) => {
                                    if (value <= 7000) {
                                        return value;
                                    }
                                    return '';
                                }
                            }),
                            color: '#495057',
                        },
                        grid: {
                            drawOnChartArea: false,
                            color: '#ebedef'
                        },
                        title: {
                            display: true,
                            text: haveModel === "N" ? t('field.model_nematode_axis') : t('field.model_solarization_axis'),
                            color: '#495057'
                        }
                    }
                } : {})
            }
        };

        return {
            multiAxisOptions
        }
    }
    const { multiAxisOptions } = getLightTheme();

    const downloadData = async (model) => {
        setCurrentModel(model);
        setShowDownloadDataDialog(true);
    };

    const downloadModelData = async (model) => {
        let response;
        const timezoneOffset = new Date().getTimezoneOffset() * -1 / 60;
        if (model && model[0] === "Nematode active"){
            response = await getExcelPredictionModel(f.id, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        }
        else if (model && model[0] === "Solarization active"){
            response = await getExcelSolarizationData(f.id, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        }
        else{
            return;
        }
        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data-model.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const downloadTemperatureData = async (model) => {
        let interval = 'H';
        const timezoneOffset = new Date().getTimezoneOffset() * -1 / 60;
        if (model && model[0] === "Nematode active"){
            interval = 'D';
        }
        const response = await getExcelTemperatureData(f.id, interval, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data-temperature.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const handleOpenMaps = () => {
        if (lat && lon) {
            const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`;
            window.open(url, '_blank');
        } else {
            console.error("Coordenadas no disponibles");
        }
    };

    const getSignalIcon = (signalSensor) => {
        switch (signalSensor) {
            case 'well_signal':
                return well_signal;
            case 'half_signal':
                return half_signal;
            case 'low_signal':
                return low_signal;
            default:
                return not_signal;
        }
    };

    const getBatteryIcon = (batterySensor) => {
        switch (batterySensor) {
            case 'low_battery':
                return low_battery;
            case 'half_battery':
                return half_battery;
            default:
                return well_battery;
        }
    };

    let sensorNumber = f && f.sensors.length > 0 ? f.sensors[0].tst_serial_number : 'no_sensor';

    let haveSensor = true;
    if (sensorNumber === 'no_sensor'){
        sensorNumber = t('field_card.no_sensor');
        haveSensor = false;
    }

    let fieldName = f ? f.name : '';
    let activeSensor = f && f.sensors.length > 0 && f.sensors[0].is_activated ? 'true' : 'false';
    let signalSensor = f && f.sensors.length > 0 ? getSignalStatus(f.sensors[0].current_rssi) : 'no_signal';
    let batterySensor = f && f.sensors.length > 0 ? f.sensors[0].current_battery : 'no_battery';

    let isOwner = userEmail === ownerEmail;
    let licenceStatus = true;

    let model = f && getModel(f);

    const handleMapIconClick = () => {
        setIsMapDialogVisible(true);
    };

    const handleCloseMapDialog = () => {
        setIsMapDialogVisible(false);
    };

    const handleOpenChartModal = (chartNumber) => {
        setIsChartModalVisible(true);
        setCurrentChart(chartNumber);
    };

    const handleCloseChartModal = () => {
        setIsChartModalVisible(false);
    };

    const menu = useRef(null);
    
    const items = [
        {
        label: t('field_card.sensor'),
        style: isOwner ? {} : {display: 'none'},
        items: [
            {
                label: '',
                style: {display: 'none'},
                command: () => {
                    
                }
            },
            {
                label: t('field_card.change_sensor'),
                style: isOwner ? {} : {display: 'none'},
                icon: 'pi pi-pencil',
                command: () => {
                    navigate('/link/sensor/'+fieldId);
                }
            }
        ]
        },
        {
        label: t('field_card.field'),
        items: [
            {
                label: t('field.location'),
                icon: 'pi pi-map-marker',
                command: () => {
                    handleMapIconClick();
                }
            },
            {
                label: t('field_card.delete_field'),
                style: isOwner ? {} : {display: 'none'},
                icon: 'pi pi-trash',
                command: () => {
                    handleDeleteField();
                }
            }
        ]
        },
        {
        label: t('field_card.model'),
        style: isOwner ? {} : {display: 'none'},
        items: [
            {
                label: haveModel === 'N' ? t('field_card.deactivate_nematode_model') : haveModel === 'S' ? t('field_card.deactivate_solarization_model') : t('field_card.activate_model'),
                icon: haveModel === 'N' || haveModel === 'S' ? 'pi pi-times' : 'pi pi-play',
                style: isOwner ? {} : {display: 'none'},
                command: () => {
                    if (haveModel === 'N') {
                        handleStopNematode();
                    } else if (haveModel === 'S') {
                        handleStopSolarization();
                    } else {
                        handleStartModel();
                    }
                }
            }
        ]
        }
    ];

    return (
        <><ConfirmDialog />

            <PostLoginWrapper isField={true} fieldId={fieldId} error={errorMessage}>
                <div className='flex flex-column align-items-center w-11 lg:w-7'>
                    {isLoadingInit ? (
                        <div className='flex justify-content-center'>
                            <div className="loading"></div>
                        </div>
                    ) : (
                        <>
                            {f && (
                                <>
                                    <div className='w-full '>
                                        <div className="flex justify-content-between align-items-center mt-5">
                                            <div className="flex align-items-center">
                                                <Badge 
                                                    className="flex align-items-center text-sm bg-white border-round-3xl pl-0 text-black border-2 border-solid"
                                                    style={{ borderColor: '#DEE2E6', lineHeight: '1.5' }}
                                                    value={
                                                        <><span 
                                                            style={{ 
                                                                width: '10px', 
                                                                height: '10px', 
                                                                borderRadius: '50%', 
                                                                marginRight: '5px',
                                                                marginLeft: '5px',
                                                                backgroundColor: haveSensor && activeSensor === 'true' ? 'var(--primary-color)' : '#FF0000'
                                                            }}
                                                        ></span>
                                                        <span>
                                                            {haveSensor ? `${sensorNumber}` : sensorNumber}
                                                        </span>
                                                        </>
                                                    }
                                                />
                                            </div>
                                            {haveSensor && activeSensor === 'true' && (
                                                <>
                                                    <span style={{ color: licenceStatus ? 'var(--primary-color)' : 'red', fontSize: '14px' }}>
                                                        {licenceStatus ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />} {t('field_card.license')}
                                                    </span>
                                                    <span className="flex align-items-center text-black text-sm">
                                                        {t(`field_card.${signalSensor}`)}
                                                        <Image src={getSignalIcon(signalSensor)} alt="signal icon" style={{ marginLeft: '2px', display: 'flex' }} pt={{ image: { width: '20px', height: '20px' } }} />
                                                    </span>
                                                    <span className="flex align-items-center text-black text-sm">
                                                        {isNaN(parseInt(batterySensor)) ? batterySensor : parseInt(batterySensor)}%
                                                        <Image src={getBatteryIcon(batterySensor)} alt="battery icon" style={{ marginLeft: '2px', display: 'flex' }} pt={{ image: { width: '20px', height: '20px' } }} />
                                                    </span>
                                                </>
                                            )}
                                            <Menu
                                                className="mt-2"
                                                model={items}
                                                popup
                                                ref={menu}
                                                id="popup_menu"
                                                pt={{
                                                    action: { style: { padding: '10px' } },
                                                    label: { style: { color: 'black' } },
                                                    icon: { style: { color: 'black' } },
                                                    submenuheader: { style: { padding: '10px', color: 'black' } }
                                                }}
                                            />

                                            <Button
                                                icon="pi pi-ellipsis-v"
                                                onClick={(event) => menu.current.toggle(event)}
                                                aria-controls="popup_menu"
                                                aria-haspopup
                                                style={{width: '14px', height: '14px', color: 'var(--primary-color)', backgroundColor: 'transparent', border: 'none', boxShadow: 'none'}}
                                            />
                                            
                                        </div>
                                        <div className="flex align-items-center justify-content-between"  style={{paddingTop: '4px'}}>
                                                <h3 className="font-bold text-black mb-0 mt-0" style={{wordBreak: 'break-all', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {fieldName}
                                                </h3>
                                            </div>
                                            
                                            <div className="flex align-items-center mt-0 mb-1">
                                                <span className="font-medium text-color-secondary text-sm">
                                                    {t(`field.crops.${crop}`)}
                                                    {model[0] === 'Nematode active' && licenceStatus && (
                                                        <>
                                                            {model[1] === 0 && ` - ${t('field_card.generation').charAt(0).toUpperCase() + t('field_card.generation').slice(1)} ${t('field_card.initial')}`}
                                                            {model[1] !== 0 && ` - ${model[1]}º ${t('field_card.generation')}`}
                                                        </>
                                                    )}
                                                </span>
                                            </div>
                                        {isLoadingTrigger ? (
                                            <div className='flex justify-content-center'>
                                                <div className="loading"></div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className='mt-3'>
                                                    {model[0] === 'Nematode active' && licenceStatus && (
                                                        <div className="mb-3">
                                                            <Accordion multiple>
                                                                <AccordionTab
                                                                    style={{
                                                                        border: '1px solid #DEE2E6',
                                                                        borderRadius: '5px' 
                                                                    }} 
                                                                    header={
                                                                        <>
                                                                            <div>
                                                                                <div className="flex justify-content-between align-items-center mb-2">
                                                                                    <span
                                                                                        className={`${model[2] > model[5] ? 'font-medium' : ''} text-color-secondary`}
                                                                                        style={model[3] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                                                                    >
                                                                                        {t('field_card.egg')}
                                                                                    </span>
                                                                                    <span
                                                                                        className={`${model[2] > model[5] ? 'font-medium' : ''} uppercase text-sm text-color-secondary`}
                                                                                        style={model[3] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                                                                    >
                                                                                        {model[3] === 'blue' ? t('field_card.wait') : (model[3] === 'orange' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.prepare')}</> : (model[3] === 'red' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.treat')}</> : t('field_card.wait')))}
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ padding: '3px', backgroundColor: "#DEE2E6" /* getColorFooter(model[3]) */, borderRadius: '50px' }}>
                                                                                    <ProgressBar
                                                                                        value={model[2] < 10 ? 10 : model[2]}
                                                                                        color={getColor(model[3])}
                                                                                        showValue={true}
                                                                                        displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500' }}>{`${model[2]} %`}</span>}
                                                                                        style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" /* getColorFooter(model[3]) */ }}
                                                                                        pt={{
                                                                                            value: {
                                                                                                className: '',
                                                                                                style: { backgroundColor: getColor(model[3]) }
                                                                                            }
                                                                                        }} />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                >
                                                                    <div className='w-full'>
                                                                        <div className='flex justify-content-center align-items-center my-3'>
                                                                            <Calendar
                                                                                id='init-date'
                                                                                style={{ height: '30px' }}
                                                                                value={initDate}
                                                                                onChange={(e) => {
                                                                                    setInitDate(e.value);
                                                                                    fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                                                                }}
                                                                                showIcon
                                                                                dateFormat="dd/mm/yy"
                                                                                readOnlyInput
                                                                                className="mr-2" />
                                                                            <Calendar
                                                                                id='last-date'
                                                                                style={{ height: '30px' }}
                                                                                value={lastDate}
                                                                                onChange={(e) => {
                                                                                    setLastDate(e.value);
                                                                                    fetchChartData(f.id, getModelId(f), initDate, e.value);
                                                                                }}
                                                                                showIcon
                                                                                dateFormat="dd/mm/yy"
                                                                                readOnlyInput
                                                                                pt={{
                                                                                    button: { className: 'bg-primary' }
                                                                                }} />
                                                                            <FontAwesomeIcon
                                                                                icon={faDownload}
                                                                                className='bg-primary'

                                                                                style={{
                                                                                    color: 'white',
                                                                                    width: '14px',
                                                                                    height: '14px',
                                                                                    marginLeft: '10px',
                                                                                    borderRadius: '7px',
                                                                                    padding: '10px',
                                                                                    paddingTop: '8px',
                                                                                    paddingBottom: '8px',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                onClick={() => downloadData(model)} />
                                                                            <div className="hidden lg:block">
                                                                                <FontAwesomeIcon
                                                                                    icon={faExpand}
                                                                                    className='bg-primary'
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                        color: 'white',
                                                                                        borderRadius: '7px',
                                                                                        padding: '10px',
                                                                                        paddingTop: '7px',
                                                                                        paddingBottom: '7px',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => handleOpenChartModal(1)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {isLoading ? (
                                                                            <div id="chart-container" style={{ position: 'relative' }} className='w-full'>
                                                                                <div className='flex justify-content-center' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                                            <div className="loading mt-0"></div>
                                                                                </div>
                                                                                <Chart
                                                                                    className='w-full'
                                                                                    style={{ height: '350px' }}
                                                                                    type="line"
                                                                                    />
                                                                            </div>
                                                                        ) : (
                                                                            <div id="chart-container" className='w-full'>
                                                                                <Chart
                                                                                    className='w-full'
                                                                                    style={{ height: '350px' }}
                                                                                    type="line"
                                                                                    data={chartData1}
                                                                                    options={multiAxisOptions}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </AccordionTab>
                                                                <AccordionTab 
                                                                    style={{
                                                                        display: sensorNumber.startsWith('P') ? 'block' : 'none',
                                                                        border: '1px solid #DEE2E6',
                                                                        borderRadius: '5px' 
                                                                    }}
                                                                    headerStyle={{
                                                                        marginTop: '10px'
                                                                    }}
                                                                    header={
                                                                        <div className="w-full">
                                                                            <div className="flex justify-content-between align-items-center mb-2">
                                                                                <span className={`${model[5] > model[2] ? 'font-medium' : ''} text-color-secondary`}>{t('field_card.larvicide')}</span>
                                                                                <span
                                                                                    className={`${model[5] > model[2] ? 'font-medium' : ''} uppercase text-sm text-color-secondary`}
                                                                                    style={model[6] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                                                                >
                                                                                    {model[6] === 'blue' ? t('field_card.wait') : (model[6] === 'orange' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.prepare')}</> : (model[6] === 'red' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.treat')}</> : t('field_card.wait')))}
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ padding: '3px', backgroundColor: "#DEE2E6" /* getColorFooter(model[6]) */, borderRadius: '50px' }}>
                                                                                <ProgressBar
                                                                                    value={model[5] < 10 ? 10 : model[5]}
                                                                                    color={getColor(model[6])}
                                                                                    showValue={true}
                                                                                    displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500' }}>{`${model[5]} %`}</span>}
                                                                                    style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" /* getColorFooter(model[6]) */ }}
                                                                                    pt={{
                                                                                        value: {
                                                                                            className: '',
                                                                                            style: { backgroundColor: getColor(model[6]) }
                                                                                        }
                                                                                    }} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <div className='w-full'>
                                                                        <div className='flex justify-content-center align-items-center my-3'>
                                                                            <Calendar
                                                                                id='init-date'
                                                                                style={{ height: '30px' }}
                                                                                value={initDate}
                                                                                onChange={(e) => {
                                                                                    setInitDate(e.value);
                                                                                    fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                                                                }}
                                                                                showIcon
                                                                                dateFormat="dd/mm/yy"
                                                                                readOnlyInput
                                                                                className="mr-2" />
                                                                            <Calendar
                                                                                id='last-date'
                                                                                style={{ height: '30px' }}
                                                                                value={lastDate}
                                                                                onChange={(e) => {
                                                                                    setLastDate(e.value);
                                                                                    fetchChartData(f.id, getModelId(f), initDate, e.value);
                                                                                }}
                                                                                showIcon
                                                                                dateFormat="dd/mm/yy"
                                                                                readOnlyInput
                                                                                pt={{
                                                                                    button: { className: 'bg-primary' }
                                                                                }} />
                                                                            <FontAwesomeIcon
                                                                                icon={faDownload}
                                                                                className='bg-primary'

                                                                                style={{
                                                                                    color: 'white',
                                                                                    width: '14px',
                                                                                    height: '14px',
                                                                                    marginLeft: '10px',
                                                                                    borderRadius: '7px',
                                                                                    padding: '10px',
                                                                                    paddingTop: '8px',
                                                                                    paddingBottom: '8px',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                onClick={() => downloadData(model)} />
                                                                            <div className="hidden lg:block">
                                                                                <FontAwesomeIcon
                                                                                    icon={faExpand}
                                                                                    className='bg-primary'
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                        color: 'white',
                                                                                        borderRadius: '7px',
                                                                                        padding: '10px',
                                                                                        paddingTop: '7px',
                                                                                        paddingBottom: '7px',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => handleOpenChartModal(2)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {isLoading ? (
                                                                            <div id="chart-container" style={{ position: 'relative' }} className='w-full'>
                                                                                <div className='flex justify-content-center' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                                            <div className="loading mt-0"></div>
                                                                                </div>
                                                                                <Chart
                                                                                    className='w-full'
                                                                                    style={{ height: '350px' }}
                                                                                    type="line"
                                                                                    />
                                                                            </div>
                                                                        ) : (
                                                                            <div id="chart-container" className='w-full'>
                                                                                <Chart
                                                                                    className='w-full'
                                                                                    style={{ height: '350px' }}
                                                                                    type="line"
                                                                                    data={chartData2}
                                                                                    options={multiAxisOptions}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </AccordionTab>
                                                            </Accordion>
                                                            <Timeline
                                                                value={events}
                                                                className='mt-3'

                                                                marker={(item) => (
                                                                    <span className="flex w-1rem h-1rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" 
                                                                        style={{ 

                                                                            backgroundColor: item.status.includes('alert') ? 'red' : 
                                                                                           item.status.includes('warning') ? 'yellow' :
                                                                                           'var(--primary-color)'
                                                                        }}>

                                                                    </span>
                                                                )}
                                                                content={(item) => (
                                                                    <div className="flex justify-content-between align-items-center w-full">
                                                                        <div className="flex flex-column align-items-start">
                                                                        <small className="text-color-secondary">
                                                                                {new Date(item.date).toLocaleDateString('es-ES', {
                                                                                    day: '2-digit',
                                                                                    month: '2-digit',
                                                                                    year: 'numeric'
                                                                                })}
                                                                            </small>
                                                                            <small className="text-color-secondary font-bold">
                                                                                {t(`notifications.${item.status}`)}
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                )}>
                                                            </Timeline>
                                                        </div>
                                                    )}

                                                    {model[0] === 'Solarization active' && licenceStatus && (
                                                        <div className="mb-3">
                                                            <Accordion multiple>
                                                                <AccordionTab
                                                                    style={{
                                                                        border: '1px solid #DEE2E6',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                    header={
                                                                        <div className="w-full">
                                                                            <div className="flex justify-content-between align-items-center mb-2">
                                                                                <span className="font-medium text-color-secondary">{t('field_card.degrees')}</span>
                                                                                <span className="font-medium uppercase text-color-secondary">
                                                                                    {model[1] < 50 ? t('field_card.null') :
                                                                                        model[1] < 500 ? t('field_card.low') :
                                                                                            model[1] < 1500 ? t('field_card.moderate') :
                                                                                                model[1] < 3000 ? t('field_card.good') :
                                                                                                    model[1] < 6000 ? t('field_card.excellent') :
                                                                                                        t('field_card.super')}
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ padding: '3px', backgroundColor: "#DEE2E6", borderRadius: '50px' }}>
                                                                                <ProgressBar
                                                                                    value={model[1] < 100 ? 13 :
                                                                                        model[1] < 1000 ? 16 :
                                                                                            model[1] < 1333 ? 19 :
                                                                                                model[1] < 1500 ? 21 :
                                                                                                    model[1] < 1777 ? 23 :
                                                                                                        model[1] < 2000 ? 25 :
                                                                                                            model[1] > 7000 ? 100 :
                                                                                                                (model[1] / 7000) * 100}
                                                                                    color={getColor(model[3])}
                                                                                    showValue={true}
                                                                                    displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500', backgroundColor: getColorCard(model) }}>{`${model[1]} ºC`}</span>}
                                                                                    style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" }}
                                                                                    pt={{
                                                                                        value: {
                                                                                            className: '',
                                                                                            style: { backgroundColor: getColorCard(model) }
                                                                                        }
                                                                                    }} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <div className='w-full'>
                                                                        <div className='flex justify-content-center align-items-center my-3'>
                                                                            <Calendar
                                                                                id='init-date'
                                                                                style={{ height: '30px' }}
                                                                                value={initDate}
                                                                                onChange={(e) => {
                                                                                    setInitDate(e.value);
                                                                                    fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                                                                }}
                                                                                showIcon
                                                                                dateFormat="dd/mm/yy"
                                                                                readOnlyInput
                                                                                className="mr-2" />
                                                                            <Calendar
                                                                                id='last-date'
                                                                                style={{ height: '30px' }}
                                                                                value={lastDate}
                                                                                onChange={(e) => {
                                                                                    setLastDate(e.value);
                                                                                    fetchChartData(f.id, getModelId(f), initDate, e.value);
                                                                                }}
                                                                                showIcon
                                                                                dateFormat="dd/mm/yy"
                                                                                readOnlyInput
                                                                                pt={{
                                                                                    button: { className: 'bg-primary' }
                                                                                }} />
                                                                            <FontAwesomeIcon
                                                                                icon={faDownload}
                                                                                className='bg-primary'
                                                                                style={{
                                                                                    color: 'white',
                                                                                    width: '14px',
                                                                                    height: '14px',
                                                                                    marginLeft: '10px',
                                                                                    borderRadius: '7px',
                                                                                    padding: '10px',
                                                                                    paddingTop: '8px',
                                                                                    paddingBottom: '8px',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                onClick={() => downloadData(model)} />
                                                                            <div className="hidden lg:block">
                                                                                <FontAwesomeIcon
                                                                                    icon={faExpand}
                                                                                    className='bg-primary'
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                        color: 'white',
                                                                                        borderRadius: '7px',
                                                                                        padding: '10px',
                                                                                        paddingTop: '7px',
                                                                                        paddingBottom: '7px',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => handleOpenChartModal(1)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {isLoading ? (
                                                                            <div id="chart-container" style={{ position: 'relative' }} className='w-full'>
                                                                                <div className='flex justify-content-center' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                                    <div className="loading mt-0"></div>
                                                                                </div>
                                                                                <Chart
                                                                                    className='w-full'
                                                                                    style={{ height: '350px' }}
                                                                                    type="line"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div id="chart-container" className='w-full'>
                                                                                <Chart
                                                                                    className='w-full'
                                                                                    style={{ height: '350px' }}
                                                                                    type="line"
                                                                                    data={chartData1}
                                                                                    options={multiAxisOptions}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </AccordionTab>
                                                            </Accordion>
                                                            <Timeline
                                                                value={events}
                                                                className='mt-3'

                                                                marker={(item) => (
                                                                    <span className="flex w-1rem h-1rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" 
                                                                        style={{ 

                                                                            backgroundColor: item.status.includes('alert') ? 'red' : 
                                                                                           item.status.includes('warning') ? 'yellow' :
                                                                                           'var(--primary-color)'
                                                                        }}>

                                                                    </span>
                                                                )}
                                                                content={(item) => (
                                                                    <div className="flex justify-content-between align-items-center w-full">
                                                                        <div className="flex flex-column align-items-start">
                                                                        <small className="text-color-secondary">
                                                                                {new Date(item.date).toLocaleDateString('es-ES', {
                                                                                    day: '2-digit',
                                                                                    month: '2-digit',
                                                                                    year: 'numeric'
                                                                                })}
                                                                            </small>
                                                                            <small className="text-color-secondary font-bold">
                                                                                {t(`notifications.${item.status}`)}
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                )}>
                                                            </Timeline>
                                                        </div>
                                                    )}

                                                    {model[0] === 'No model' && licenceStatus && (
                                                        <div className="mb-3">
                                                            <Accordion multiple>
                                                                <AccordionTab
                                                                    style={{
                                                                        border: '1px solid #DEE2E6',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                    header={
                                                                        <div className="w-full">
                                                                            <div className="flex justify-content-between align-items-center">
                                                                                <span className="font-medium text-color-secondary">{t('field.temperature')}</span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <div className='w-full'>
                                                                        <div className='flex justify-content-center align-items-center my-3'>
                                                                            <Calendar
                                                                                id='init-date'
                                                                                style={{ height: '30px' }}
                                                                                value={initDate}
                                                                                onChange={(e) => {
                                                                                    setInitDate(e.value);
                                                                                    fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                                                                }}
                                                                                showIcon
                                                                                dateFormat="dd/mm/yy"
                                                                                readOnlyInput
                                                                                className="mr-2" />
                                                                            <Calendar
                                                                                id='last-date'
                                                                                style={{ height: '30px' }}
                                                                                value={lastDate}
                                                                                onChange={(e) => {
                                                                                    setLastDate(e.value);
                                                                                    fetchChartData(f.id, getModelId(f), initDate, e.value);
                                                                                }}
                                                                                showIcon
                                                                                dateFormat="dd/mm/yy"
                                                                                readOnlyInput
                                                                                pt={{
                                                                                    button: { className: 'bg-primary' }
                                                                                }} />
                                                                            <FontAwesomeIcon
                                                                                icon={faDownload}
                                                                                className='bg-primary'
                                                                                style={{
                                                                                    color: 'white',
                                                                                    width: '14px',
                                                                                    height: '14px',
                                                                                    marginLeft: '10px',
                                                                                    borderRadius: '7px',
                                                                                    padding: '10px',
                                                                                    paddingTop: '8px',
                                                                                    paddingBottom: '8px',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                onClick={() => downloadData(model)} />
                                                                            <div className="hidden lg:block">
                                                                                <FontAwesomeIcon
                                                                                    icon={faExpand}
                                                                                    className='bg-primary'
                                                                                    style={{
                                                                                        marginLeft: '10px',
                                                                                        color: 'white',
                                                                                        borderRadius: '7px',
                                                                                        padding: '10px',
                                                                                        paddingTop: '7px',
                                                                                        paddingBottom: '7px',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => handleOpenChartModal(1)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {isLoading ? (
                                                                            <div id="chart-container" style={{ position: 'relative' }} className='w-full'>
                                                                                <div className='flex justify-content-center' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                                    <div className="loading mt-0"></div>
                                                                                </div>
                                                                                <Chart
                                                                                    className='w-full'
                                                                                    style={{ height: '350px' }}
                                                                                    type="line"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div id="chart-container" className='w-full'>
                                                                                <Chart
                                                                                    className='w-full'
                                                                                    style={{ height: '350px' }}
                                                                                    type="line"
                                                                                    data={chartData1}
                                                                                    options={multiAxisOptions}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </AccordionTab>
                                                            </Accordion>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    <ModalInterpolate
                        isOpen={isInterpolateModalOpen}
                        onClose={closeInterpolateModal}
                        sensorId={f && f.sensors[0] ? f.sensors[0].id : null} />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showStartModelDialog}
                        message={t('field.start_model_message')}
                        header={t('field.adverse')}
                        icon="pi pi-exclamation-triangle"
                        acceptLabel={t('field.nematode_model_button')}
                        rejectLabel={t('field.solarization_model_button')}
                        acceptClassName="bg-[#449E48] w-6"
                        rejectClassName="bg-[#449E48] w-6"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowStartModelDialog(false)}
                        accept={async () => {
                            setHaveModel('N');
                            await handleStartNematode();
                            setShowStartModelDialog(false);
                        }}
                        reject={async () => {
                            setHaveModel('S');
                            await handleStartSolarization();
                            setShowStartModelDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showStopNematodeDialog}
                        message={t('field.stop_nematode_message')}
                        header={t('field.adverse')}
                        icon="pi pi-exclamation-triangle"
                        acceptLabel={t('field.confirmDialog')}
                        rejectLabel={t('field.rejectDialog')}
                        acceptClassName="bg-[#449E48] w-3"
                        rejectClassName="bg-[#449E48] w-3"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowStopNematodeDialog(false)}
                        accept={async () => {
                            setHaveModel('No model');
                            await putNematode(f.id, f.active_prediction_model.id);
                            setRefreshTrigger(prev => !prev);
                            setIsLoadingTrigger(true);
                            setShowStopNematodeDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showStopSolarizationDialog}
                        message={t('field.stop_solarization_message')}
                        header={t('field.adverse')}
                        icon="pi pi-exclamation-triangle"
                        acceptLabel={t('field.confirmDialog')}
                        rejectLabel={t('field.rejectDialog')}
                        acceptClassName="bg-[#449E48] w-3"
                        rejectClassName="bg-[#449E48] w-3"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowStopSolarizationDialog(false)}
                        accept={async () => {
                            setHaveModel('No model');
                            await putSolarization(f.id, f.active_solarization.id);
                            setRefreshTrigger(prev => !prev);
                            setIsLoadingTrigger(true);
                            setShowStopSolarizationDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showDeleteFieldDialog}
                        message={t('field.delete_field_message')}
                        header={t('field.adverse')}
                        acceptLabel={t('field.confirmDialog')}
                        acceptClassName="bg-[#449E48]"
                        rejectClassName="hidden"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowDeleteFieldDialog(false)}
                        accept={async () => {
                            await deleteField(f.id);
                            setShowDeleteFieldDialog(false);
                            navigate('/fields');
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showDownloadDataDialog}
                        message={t('field.download_data_message')}
                        header={t('field.download_data')}
                        acceptLabel={t('field.model')}
                        rejectLabel={t('field.temperature')}
                        acceptClassName={model && model[0] === 'No model' ? 'hidden' : 'bg-[#449E48] w-6'}
                        rejectClassName='bg-[#449E48] w-6'
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowDownloadDataDialog(false)}
                        accept={async () => {
                            await downloadModelData(model);
                            setShowDownloadDataDialog(false);
                        }}
                        reject={async () => {
                            await downloadTemperatureData(model);
                            setShowDownloadDataDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <Dialog 
                        header={<span className='text-color-secondary'>{t('field.location')}</span>}
                        footer={
                            <div className='flex flex-column align-items-center'>
                            <div className="flex flex-column" style={{paddingTop: '1.5rem'}} onClick={() => {
                            }}>
                                    <span className='text-color-secondary'>
                                        {t('field.latitude')}: {lat.toFixed(3)} | {t('field.longitude')}: {lon.toFixed(3)}
                                    </span>
                                    <span className='text-color-secondary pt-1'>
                                        {t('field.address')}: {address}
                                    </span>
                            </div>
                            <span 
                                className='underline text-color-secondary pt-1 cursor-pointer' 
                                onClick={() => handleOpenMaps()}>
                                    {t('field.open_maps')}
                            </span>
                            </div>
                        }
                        visible={isMapDialogVisible} 
                        onHide={() => setIsMapDialogVisible(false)}
                        className='w-11 p-0 border-round-2xl'
                        focusOnShow={false}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            content: {className: 'p-0'},
                            footer: {className: 'border-round-bottom-2xl text-center'}
                        }}
                    >
                        <div className='flex align-items-center justify-content-center w-full map-div'>
                            <Map latitude={lat} longitude={lon} onMapLoad={handleMapLoad} clickable={false} />
                        </div>
                    </Dialog>

                    <Dialog
                        visible={isChartModalVisible}
                        onHide={handleCloseChartModal}
                        className='w-11 p-0 border-round-2xl'
                        focusOnShow={false}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            content: { className: 'border-round-bottom-2xl' }
                        }}
                    >
                        <div className='flex flex-column align-items-center w-full'>
                            <div className='flex justify-content-center align-items-center my-3'>
                                <Calendar
                                    id='modal-init-date'
                                    style={{ height: '30px' }}
                                    value={initDate}
                                    onChange={(e) => {
                                        setInitDate(e.value);
                                        fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                    }}
                                    showIcon
                                    dateFormat="dd/mm/yy"
                                    readOnlyInput
                                    className="mr-2" />
                                <Calendar
                                    id='modal-last-date'
                                    style={{ height: '30px' }}
                                    value={lastDate}
                                    onChange={(e) => {
                                        setLastDate(e.value);
                                        fetchChartData(f.id, getModelId(f), initDate, e.value);
                                    }}
                                    showIcon
                                    dateFormat="dd/mm/yy"
                                    readOnlyInput />
                            </div>
                            <Chart
                                className='w-full'
                                style={{ height: '500px' }}
                                type="line"
                                data={currentChart === 1 ? chartData1 : chartData2}
                                options={multiAxisOptions}
                            />
                        </div>
                    </Dialog>
                </div>
            </PostLoginWrapper>
        </>
    );
};