import React, { useEffect, useRef, useState } from 'react';
import { getFields } from '../actions/fieldsActions';
import { FieldCard } from './FieldCard';
import { getCrops } from '../actions/fieldsActions';
import { getSignalStatus, getModel, getModelName, getOwner } from '../utils/fieldUtils';
import { messaging } from '../firebase';
import { getToken } from 'firebase/messaging';
import { processGCM } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'; 
import { useNavigate } from 'react-router-dom';
import { getUser } from '../actions/userActions';
import { TabMenu } from 'primereact/tabmenu';
import { useSwipeable } from 'react-swipeable';
import { PostLoginWrapper } from './PostLoginWrapper';
import { useTranslation } from 'react-i18next';

export const Fields = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [fields, setFields] = useState([]);
    const [error, setError] = useState('');
    const [userId, setUserId] = useState('');
    const [userEmail, setUserEmail ] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedModel, setSelectedModel] = useState(localStorage.getItem('selectedModel') || 'nematode');
    const [activeIndex, setActiveIndex] = useState(parseInt(localStorage.getItem('activeIndex')) || 0);
    const [selectedOwner, setSelectedOwner] = useState(localStorage.getItem('selectedOwner') || '');
    const fieldListRef = useRef(null);
    const [items, setItems] = useState([
        { label: t('fields.nematode'), value: 'nematode' },
        { label: t('fields.solarization'), value: 'solarization' },
        { label: t('fields.no_model'), value: 'no_model' }
    ]);
    const [trigger, setTrigger] = useState(false);
    const [fieldsPerRow, setFieldsPerRow] = useState();

    const CARD_WIDTH = 450;

    useEffect(() => {
        const abortController = new AbortController();
        const fetchData = async () => {
            const userData = await getUser();
            setUserId(userData.id);
            setUserEmail(userData.email);
            try {
                setFields([]);
                await getFields(setFields, setIsLoading, abortController.signal);
            } catch (error) {
                setError(error);
            }
        };
        fetchData();

        const requestPermissionAndRegisterSW = async () => {
            try {
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
                    const token = await getToken(messaging, {
                        vapidKey: 'BHARREGztf47ESSTmbIR8yMW2qF2ZSVnuZNqrX2HplkKWO1ndN7HoDQQyr1MsZextr8A9dTZQDVnFqf1IvBwYPw',
                        serviceWorkerRegistration: registration,
                    });
                    if (token) {
                        await processGCM(token);
                    }
                }
            } catch (error) {
                console.error('Error getting FCM token:', error);
            }
        };

        requestPermissionAndRegisterSW();

        return () => {
            abortController.abort();
        };
    }, [trigger]);

    useEffect(() => {
        const numNematode = fields.filter((f) => getModelName(f) === 'nematode').length;
        const numSolarization = fields.filter((f) => getModelName(f) === 'solarization').length;
        const numNoModel = fields.filter((f) => getModelName(f) === 'no_model').length;

        const createItem = (labelText, count, value) => ({
            label: (
                <div style={{ textAlign: 'center' }}>
                    <span>{labelText}</span>
                    <div style={{ marginTop: '4px' }}>({count})</div>
                </div>
            ),
            value: value
        });

        setItems([
            createItem(t('fields.nematode'), numNematode, 'nematode'),
            createItem(t('fields.solarization'), numSolarization, 'solarization'),
            createItem(t('fields.no_model'), numNoModel, 'no_model')
        ]);
    }, [fields]);

    const handleCreateField = () => {
        setError('');
        navigate('/createField');
    };

    const filteredFields = fields.filter((f) => {
        const modelMatch = selectedModel ? getModelName(f) === selectedModel : true;
        const ownerMatch = selectedOwner === 'own' ? getOwner(f) === userEmail : selectedOwner === 'guest' ? getOwner(f) !== userEmail : true;
        return modelMatch && ownerMatch;
    });

    const paddedFields = [...filteredFields];
    const remainder = filteredFields.length % fieldsPerRow;

    if (remainder !== 0) {
        const emptyFieldsNeeded = fieldsPerRow - remainder;
        for (let i = 0; i < emptyFieldsNeeded; i++) {
            paddedFields.push({ id: `empty-${i}`, isEmpty: true });
        }
    }

    const handleTabChange = (e) => {
        const index = e.index !== undefined ? e.index : e;
        const value = items[index].value;
        setActiveIndex(index);
        localStorage.setItem('activeIndex', index);
        setSelectedModel(value);
        localStorage.setItem('selectedModel', value);

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            const newIndex = (activeIndex + 1) % items.length;
            handleTabChange(newIndex);
        },
        onSwipedRight: () => {
            const newIndex = (activeIndex - 1 + items.length) % items.length;
            handleTabChange(newIndex);
        },
        delta: 100,
        trackMouse: true
    });

    useEffect(() => {
        const updateFieldsPerRow = () => {
            const containerWidth = fieldListRef.current ? fieldListRef.current.offsetWidth : window.innerWidth;
            const newFieldsPerRow = Math.floor(containerWidth / CARD_WIDTH);
            setFieldsPerRow(newFieldsPerRow);
        };

        updateFieldsPerRow();

        window.addEventListener('resize', updateFieldsPerRow);

        return () => {
            window.removeEventListener('resize', updateFieldsPerRow);
        };
    }, [fields]);

    return (
        <PostLoginWrapper error={error} isMain={true}>
            {isLoading ? (
                <div className="loading"></div>
            ) : (
            <div className='w-full flex flex-column' {...handlers} style={{ minHeight: '80vh' }}>
                <TabMenu 
                    className='w-full' 
                    model={items} 
                    activeIndex={activeIndex} 
                    onTabChange={handleTabChange} 
                    pt={{
                        menu: {
                            className: 'w-full flex justify-content-between'
                        },
                        menuitem: {
                            className: 'flex-1 flex justify-content-center'
                        },
                        action: {
                            className: 'w-full flex justify-content-center',
                            style: {
                                margin: '0',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                            }
                        }
                    }}
                />
                <div
                    className='flex w-full pb-8 desktop-field-list mobile-field-list'
                    ref={fieldListRef}
                >
                    {paddedFields.map((f) => {
                        if (f.isEmpty) {
                            return <div key={f.id} className='w-11' style={{ visibility: 'hidden', maxWidth: fieldsPerRow === 0 || fieldsPerRow === 1 ? '100%' : fieldsPerRow === 2 ? '48%' : fieldsPerRow === 3 ? '32%' : '24%'}} />;
                        }
                        return (
                            <FieldCard 
                                key={f.id}

                                fieldId={f.id}
                                fieldName={f.name} 
                                sensorNumber={f.sensors.length > 0 ? f.sensors[0].tst_serial_number : 'no_sensor'}   
                                activeSensor={f.sensors.length > 0 && f.sensors[0].is_activated ? 'true' : 'false'}
                                signalSensor={f.sensors.length > 0 ? getSignalStatus(f.sensors[0].current_rssi) : 'no_signal'}
                                batterySensor={f.sensors.length > 0 ? f.sensors[0].current_battery : 'no_battery'}
                                cropId={f.crop}
                                model={getModel(f)}
                                ownerEmail={getOwner(f)}
                                userEmailLogin={userEmail}
                                userId={userId}
                                expirationLicenseDate={f.expiration_license_date}
                                isFields={true}
                                fieldsPerRow={fieldsPerRow}
                                setTrigger={setTrigger}
                            />

                        );
                    })}
                </div>
                
                <div
                    className="fixed bottom-0 left-0 w-full bg-white p-3 shadow-2 flex align-items-center justify-content-center cursor-pointer"
                    style={{ zIndex: 1 }}
                    onClick={handleCreateField}
                >
                    <div className="flex align-items-center gap-2 no-tap-highlight">
                        <FontAwesomeIcon className='svg-settings-add' icon={faPlus} style={{ color: 'var(--primary-color)' }} />
                        <span className="font-medium" style={{ color: 'var(--primary-color)' }}>{t('fields.add_field')}</span>
                    </div>
                </div>
            </div>
            )}
        </PostLoginWrapper>
    );
};
